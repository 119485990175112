<template>
  <section>
    <TheHeader/>
    <TheBanner />
    <ServiceHome />
    <ProjectRec />
    <ContactForm />
  </section>
</template>

<script>
import TheBanner from "@/components/TheBanner.vue";
import ServiceHome from "@/components/ServiceHome.vue";
import ProjectRec from "@/components/ProjectRec.vue";
import ContactForm from "@/components/ContactForm.vue";
import TheHeader from "@/components/TheHeader.vue";

export default {

  name: "HomeView",
  components: {
    TheBanner,
    ServiceHome,
    ProjectRec,
    ContactForm,
    TheHeader,
  },
  
};
</script>

<style scoped>

</style>