<template>
  <section>
    <TheHeader />
    <PortfolioHome />
  </section>
</template>

<script>
import PortfolioHome from "@/components/PortfolioHome.vue";
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: "PortfolioView",

  components: {
    PortfolioHome,
    TheHeader,
  },
};
</script>

<style>
</style>