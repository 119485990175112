<template>
  <section>
    <TheHeader />
    <ProjectPage />
  </section>
</template>

<script>
import ProjectPage from "@/components/ProjectPage.vue";
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: "ProjectView",

  components: {
    ProjectPage,
    TheHeader,
  },
};
</script>

<style>
</style>