<template>
  <section class="contact-bg">
    <div class="contacto-content">
      <form @submit.prevent="enviarMensagem">
        <div class="form">
          <div class="titulo">
            <span class="semi-titulo">Contacto</span>

            <h2 class="generico-h2">Ficaremos felizes em respondê-lo</h2>
          </div>

          <div class="row-2">
            <input
              type="text"
              name="nome"
              v-model="nome"
              placeholder="Nome Completo"
            />
            <input
              type="text"
              name="empresa"
              v-model="empresa"
              placeholder="Empresa"
            />
          </div>
          <input
            class="mb-4"
            name="email"
            v-model="email"
            type="email"
            placeholder="Email"
          />
          <input
            class="mb-4"
            name="telefone"
            v-model="telefone"
            type="number"
            placeholder="Telefone"
          />

          <div>
            <textarea
              name="mensagem"
              v-model="mensagem"
              id=""
              cols="30"
              rows="10"
              placeholder="Escreva sua mensagem"
            ></textarea>
          </div>
        </div>
        <button type="submit" class="botao">Enviar a mensagem</button>
      </form>
    </div>

    <div class="imagem-contacto">
      <img :src="sobrefoto" alt="Mirimo" />
    </div>
  </section>
</template>

<script>
import fetchData from "@/fetchData.js";
import axios from "axios";
import swal from "sweetalert2";

export default {
  name: "ContactForm",
  mixins: [fetchData],

  data() {
    return {
      nome: "",
      empresa: "",
      // service_id: "",
      email: "",
      telefone: "",
      mensagem: "",
    };
  },

  methods: {
    async enviarMensagem() {
      try {
        await axios.post("https://backoffice.mirimo.ao/api/contacto", {
          nome: this.nome,
          empresa: this.empresa,
          // service_id: this.service_id,
          email: this.email,
          telefone: this.telefone,
          mensagem: this.mensagem,
        });

        // Limpar os campos após o envio bem-sucedido
        this.nome = "";
        this.empresa = "";
        this.service_id = "";
        this.email = "";
        this.telefone = "";
        this.mensagem = "";

        swal.fire({
          position: "center",
          icon: "success",
          title: "A sua mensagem foi enviada, obrigado",
          showConfirmButton: false,
          timer: 2000,
        });
      } catch (error) {
        // console.error("Erro ao enviar a mensagem:", error);
        swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Ocorreu um erro ao enviar a mensagem.",
        });
        // Aqui você pode tratar o erro, exibir uma mensagem para o usuário, etc.
      }
    },
  },

  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
.contact-bg {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.imagem-contacto {
  flex: 1;
}
.imagem-contacto img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: right center;
}

.contacto-content {
  box-sizing: border-box;
  max-width: 1400px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
}

.row-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.row-2 input {
  padding: 18px;
  border-radius: 10px;
  border: none;
  background: #313131;
  font-size: 16px;
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
  color: #fff;
}

.row-2 input:focus {
  outline: 1px solid var(--bg-azul);
}

.form input {
  padding: 18px;
  border-radius: 10px;
  border: none;
  background: #313131;
  color: #fff;
  width: 100%;
  font-size: 16px;
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
}

.form input:focus {
  outline: 1px solid var(--bg-azul);
}

textarea {
  width: 100%;
  padding: 18px;
  background: #313131;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}

.form textarea:focus {
  outline: 1px solid var(--bg-azul);
}

button {
  display: inline;
  width: 100%;
  max-width: 100%;
}

@media (max-width: 900px) {
  .contact-bg {
    grid-template-columns: 1fr;
  }

  .imagem-contacto img {
    display: none;
  }
}
@media (max-width: 500px) {
  .row-2 {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 10px;
    margin-bottom: 10px;
  }

  .contacto-content {
    padding-top: 20px;
  }
}
</style>