<template>
  <section>
    <div class="borda container"></div>

    <div class="contacto container-sec">
      <h4>Vamos criar algo Juntos!</h4>
      <router-link to="/projecto" class="botao seta pro">Entrar em contacto</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "BordaContacto",
};
</script>

<style>
</style>