<template>
  <section>
    <ProjectInfo class="background-white" />
  </section>
</template>

<script>
import ProjectInfo from "@/components/ProjectInfo.vue";

export default {
  name: "ProjectDetail",
  components: {
    ProjectInfo,
  },
};
</script>

<style scoped>
</style>