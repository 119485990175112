<template>
  <section class="service-bg cor-preta-sec">
    <div class="services-content container-sec">
      <div class="titulo">
        <span class="semi-titulo">OS NOSSOS SERVIÇOS</span>
        <h2 class="generico-h2">
          Projectamos para fazer o seu negócio crescer
        </h2>
        <router-link class="botao seta pro" to="/projecto"
          >Seu projecto</router-link
        >
      </div>

      <div class="services">

        <div class="service" v-for="service in services" :key="service.index">
          <div class="service-content">
            <img :src="service.photo" alt="" />
            <h3>{{service.titulo}}</h3>
          </div>
          <p>
            {{service.descricaoHome}}
          </p>
        </div>

       
      </div>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";

export default {
  name: "ServiceHome",

  data() {
    return {
      services: "",
      generics: "",
    };
  },

  methods: {
    getServices() {
      api.get("/services").then((response) => {
        // console.log(response.data);
        this.services = response.data;
      });
    },
    
  },
  created() {
    this.getServices();
    // this.getPhoto();
  },
};
</script>

<style scoped>
.service-bg {
  padding: 80px 0px;
}

.services-content {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 80px;
}

.service-content {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.service {
  margin-bottom: 50px;
}

.service-content img {
  width: 80px;
  margin: -6px;
}

.service h3 {
  font-size: 35px;
  font-weight: 800;
  color: var(--bg-branco);
}

.service p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  color: var(--bg-preto-p);
}

@media (max-width: 800px) {
  .service-bg {
    padding: 40px 0px;
  }

  .service-content img {
    width: 60px;
  }

  .service h3 {
    font-size: 30px;
  }
}
@media (max-width: 500px) {
  .service {
    margin-bottom: 25px;
  }
}

/* .service:nth-child(even) {
  flex-direction: row-reverse; 
} */
</style>