<template>
  <section>
    <TheHeader />
    <ServicePage />
  </section>
</template>

<script>
import ServicePage from "@/components/ServicePage.vue";
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: "ServiceView",

  components: {
    ServicePage,
    TheHeader,
  },
};
</script>

<style>
</style>