<template>
  <section class="projects-bg">
    <div class="projects-content container-sec">
      <div class="titulo">
        <span class="semi-titulo">Projectos em Destaque </span>
        <h2 class="generico-h2">
          Explore a diversidade das nossas criações cativantes que oferecem
          experiências imersivas.
        </h2>
      </div>

      <div class="projects">
        <router-link
          :to="{name: 'project', params: {id: project.slug}}"
          v-for="(project, index) in projects"
          :key="index"
        >
          <div class="project">
            <div class="card">
              <img class="imagem" :src="project.fotocard" :alt="project.nome" />
              <h3>{{ project.nome }}</h3>
              <p>
                {{ project.bannerTitulo }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";
export default {
  name: "ProjectRec",
  data() {
    return {
      projects: "",
    };
  },

  methods: {
    getProjects() {
      api.get("/projects").then((response) => {
        // console.log(response.data);
        this.projects = response.data;
      });
    },
    
  },

  created() {
    this.getProjects();
  },
};
</script>

<style scoped>
.projects-bg {
  background: var(--bg-branco);
  padding: 80px 0px;
}

.generico-h2 {
  color: var(--bg-preto);
  max-width: 1200px;
}

.projects {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 80px;
}

.project {
  background: var(--bg-cinza);
  padding: 35px;
  border-radius: 16px;
}

.project:hover {
  box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
  transform: scale(1.1);
  position: relative;
  transition: 0.5s;
  z-index: 1;
}

.project h3 {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 10px;
}
.project p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  color: var(--bg-preto-sec);
}

.project img {
  border-radius: 16px;
  margin-bottom: 30px;
}

@media (max-width: 800px) {
  .projects-bg {
    padding: 35px 0px;
  }

  .project {
    padding: 20px;
    border-radius: 16px;
  }

  .projects {
    gap: 40px;
  }

  .project img {
    margin-bottom: 20px;
  }
}
</style>