import Vue from 'vue'
import VueRouter from 'vue-router'
import 'boxicons'
import HomeView from '../views/HomeView.vue'
import PortfolioView from '../views/PortfolioView.vue'
import ServiceView from '../views/ServiceView.vue'
import AboutView from '../views/AboutView.vue'
import ProjectView from '../views/ProjectView.vue'
import ProjectDetail from '../views/ProjectDetail.vue'
import ProjectInfo from '../components/ProjectInfo.vue'
// import PortfolioHome from '../components/PortfolioHome.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
    
  },

  {
    path: '/portfolio',
    name: 'portfolio',
    component: PortfolioView
    
  },

  {
    path: '/services',
    name: 'services',
    component: ServiceView
  },
  {
    path: '/sobre',
    name: 'sobre',
    component: AboutView
  },
  {
    path: '/projecto',
    name: 'projecto',
    component: ProjectView
  },

  {
    path: '/projectoDetail',
    name: 'projectoDetail',
    component: ProjectDetail
  },
  
  {
    path: '/project/:id',
    name: 'project',
    component: ProjectInfo,
    props: true
  },
  {
    path: '/service/:id',
    name: 'service',
    component: PortfolioView,
    props: true
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
