<template>
  <section class="about-bg">
    <div class="container-sec">
      <div class="titulo-seccao">
        <h2 class="generico-page-h2 project">{{ projectoTitulo }}</h2>
      </div>
      <ProjectForm />
    </div>
  </section>
</template>

<script>
import ProjectForm from "@/components/ProjectForm.vue";
import fetchData from "@/fetchData.js";

export default {
  name: "ProjectPage",
  mixins: [fetchData],
  components: {
    ProjectForm,
  },

  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
.project {
  margin-bottom: 80px;
  text-align: center;
}
</style>