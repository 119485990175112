<template>
  <section class="about-bg">
    <div class="container-sec">
      <div class="titulo-seccao">
        <h2 class="generico-page-h2">{{sobreTitulo}}</h2>
        <p class="paragraph">
          {{sobreDescricao}}
        </p>
      </div>
    </div>
    <div class="form-contact">
      <ContactForm />
    </div>
  </section>
</template>

<script>
import fetchData from "@/fetchData.js";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "AboutPage",
  mixins: [fetchData],

  components: {
    ContactForm,
  },

  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
.form-contact {
  margin-top: 7rem;
}

.form-contact {
  background: var(--bg-preto-sec);
  /* padding-bottom: 50px; */
}
</style>