<template>
  <section class="service-bg">
    <div class="container-sec">
      <div class="titulo-seccao">
        <h2 class="generico-page-h2">{{servicoTitulo}}</h2>
        <p class="paragraph">
         {{servicoDescricao}}
        </p>
      </div>

      <div class="midea">
        <div class="midea-content">
          <img :src="servicofoto" alt="">
        </div>
      </div>
    </div>
    <div class="services">
      <ServiceHome />
    </div>
  </section>
</template>

<script>
import ServiceHome from "@/components/ServiceHome.vue";
import fetchData from "@/fetchData.js";

export default {
  name: "ServicePage",
  mixins: [fetchData],
  components: {
    ServiceHome,
  },

  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
.service-bg {
  padding-top: 5rem;
}
.titulo-seccao {
  margin-bottom: 5rem;
}

.services {
  margin-top: 40px;
}

@media (max-width: 800px) {
  .titulo-seccao {
    margin-bottom: 2rem;
  }
}
</style>