<template>
  <main class="banner-bg">
    <div class="banner-content container-sec">
      <div class="titulo">
        <h1>{{homeTitulo}}<span class="ponto">.</span></h1>
      </div>
      <p>
        {{homeDescricao}}
      </p>
    </div>

    <!-- <div class="midea container">
      <div class="midea-content" v-for="banner in banners" :key="banner.index">
        <img :src="banner.photo" alt="" />
      </div>
    </div> -->

    <div class="midea container">
      <div
        id="carouselExampleFade"
        class="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div
            class="carousel-item active"
            v-for="banner in banners"
            :key="banner.index"
          >
            <img :src="banner.photo" alt="" />
          </div>
        </div>
        <!-- <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> -->
      </div>
    </div>

    <!-- <div class="midea container">
      <div class="midea-content" >
        <img src="@/assets/photos/walu.jpg" alt="" />
      </div>
    </div> -->
  </main>
</template>

<script>
import { api } from "@/services.js";
import fetchData from "@/fetchData.js";

export default {
  name: "TheBanner",
  mixins  : [fetchData],

  data() {
    return {
      banners: "",
    };
  },

  methods: {
    getBanners() {
      api.get("/banners").then((response) => {
        // console.log(response.data)
        this.banners = response.data;
      })
       .catch(error => {
        console.log(error);
      });
    },
  },
  created() {
    this.getBanners();
    this.fetchData();
    // this.getPhoto();
  },
};
</script>

<style scoped>
.banner-bg {
  background: var(--bg-preto);
  padding-bottom: 80px;
}

.banner-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.banner-content h1 {
  font-size: 10rem;
  font-weight: 700;
  color: var(--bg-branco);
  display: inline-block;
  line-height: 1.1;
  margin-bottom: 50px;
}

.banner-content p {
  font-size: 22px;
  font-weight: 400;
  color: var(--bg-preto-3);
  display: inline-block;
  line-height: 1.5;
  max-width: 1000px;
}

.ponto {
  color: var(--bg-azul);
  margin: 0;
}

@media (max-width: 1200px) {
  .banner-content h1 {
    font-size: 7rem;
  }
}

@media (max-width: 900px) {
  .banner-content h1 {
    font-size: 6rem;
  }
}

@media (max-width: 800px) {
  .banner-content h1 {
    font-size: 5rem;
  }
}

@media (max-width: 500px) {
  .banner-content h1 {
    font-size: 60px;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 30px;
  }
  .banner-content p {
    font-size: 18px;
    color: var(--bg-preto-p);
  }

  .banner-content {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
</style>