<template>
  <section class="portfolio-bg">
    <div class="container-sec ty-bg">
      <div class="titulo-seccao">
        <h2 class="generico-page-h2">
          {{ portfolioTitulo }}
        </h2>
        <p class="paragraph">
          {{ portfolioDescricao }}
        </p>
      </div>
      <div class="services">
        <nav class="menu">
          <ul>
            <router-link :to="{ name: 'service', params: { id: '' } }">
              Todos
            </router-link>

            <router-link
              :to="{ name: 'service', params: { id: service.slug } }"
              v-for="service in services"
              :key="service.index"
            >
              {{ service.titulo }}
            </router-link>
          </ul>
        </nav>
      </div>

      <div
        class="cards-project"
        v-if="filteredProjects && filteredProjects.length"
      >
        <router-link
          :to="{ name: 'project', params: { id: project.slug } }"
          v-for="(project, index) in filteredProjects"
          :key="index"
        >
          <div class="card">
            <img :src="project.fotocard" :alt="project.nome" />
            <div class="content">
              <div class="flex">
                <h3>{{ project.nome }}</h3>
                <span>{{ project.service.titulo }}</span>
              </div>
              <p>{{ project.bannerTitulo }}</p>
            </div>
          </div>
        </router-link>
      </div>

      <div v-else-if="filteredProjects && filteredProjects.length === 0">
        <p class="sem-resultados">Sem projectos</p>
      </div>
    </div>

    <BordaContacto />
  </section>
</template>

<script>
import BordaContacto from "@/components/BordaContacto.vue";
// import ProjectosPaginar from "@/components/ProjectosPaginar.vue";
// import { serialize } from "@/helpers.js";

import { api } from "@/services";
import fetchData from "@/fetchData.js";

export default {
  name: "PortfolioHome",
  mixins: [fetchData],

  data() {
    return {
      projects: [],
      services: "",
    };
  },
  components: {
    BordaContacto,
  },

  computed: {
    filteredProjects() {
      const serviceId = this.$route.params.id;
      // Verificar se o parâmetro id está presente
      if (serviceId) {
        return this.projects.filter(
          (project) => project.service.slug === serviceId
        );
      } else {
        // Se não houver parâmetro id, retornar todos os projetos
        return this.projects;
      }
    },
  },
  methods: {
    redirecionarParaService(service) {
      this.$router.push({ name: "service", params: { id: service.id } });
    },
    getProjects() {
      api
        .get("allProjects")
        .then((response) => {
          // console.log(response);
          this.projects = response.data;
        })
        .catch((error) => {
          console.error("Erro ao carregar projetos:", error);
        });
    },
    getServices() {
      api
        .get("services")
        .then((response) => {
          this.services = response.data;
        })
        .catch((error) => {
          console.error("Erro ao carregar serviços:", error);
        });
    },
    // getService() {
    //   api
    //     .get(`/service/${this.id}`)
    //     .then((response) => {
    //       console.log(response.data);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },

  created() {
    this.getProjects();
    this.getServices();
    this.fetchData();
  },
};
</script>

<style scoped>


.flex {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.services {
  margin-top: 50px;
}

.card img:hover {
  box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
  transform: scale(1.02);
  position: relative;
  transition: 0.5s;
  z-index: 1;
}

.services ul {
  color: var(--bg-branco);
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 10px;
  max-width: max-content;
}
.services ul a:hover {
  background: var(--bg-branco);
  color: var(--bg-preto);
  border: 1px solid var(--bg-branco);
}

a.router-link-exact-active{
   background: var(--bg-branco);
  color: var(--bg-preto);
  border: 1px solid var(--bg-branco);
}

.services a {
  border: 1px solid var(--bg-preto-b);
  padding: 10px 20px;
  border-radius: 50px;
}

.cards-project {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 40px;
}

.card img {
  border-radius: 10px;
}

.card span {
  font-size: 15px;
  display: block;
  max-width: max-content;
  padding: 10px;
  color: var(--bg-preto-3);
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 50px;
  border: 1px solid var(--bg-preto-b);
}

.card h3 {
  font-size: 30px;
  display: inline-block;
  color: var(--bg-preto-3);
  /* margin-bottom: 10px; */
}

.card p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bg-preto-p);
}

.sem-resultados {
  font-size: 40px;
  margin-top: 30px;
  color: white;
  font-weight: 800;
}

@media (max-width: 500px) {
  .menu ul {
    display: flex;
    font-weight: 300;
    font-size: 13px;
    flex-wrap: wrap;
    border: none;
    gap: 15px;
  }

  .menu ul a {
    padding: 8px 15px;
    background: var(--bg-hr);
    border-radius: 20px;
  }

  .categories ul {
    padding: 0px 0px;
  }
  .cards-project {
    margin-top: 30px;
  }

  .cards-project {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 40px;
  }

  .card span {
    font-size: 10px;
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 50px;
    background: var(--bg-preto-sec);
    border: none;
  }

  .card p {
    font-size: 15px;
  }

  .content {
    margin-left: 15px;
  }

  .card h3 {
    font-size: 25px;
    display: inline-block;
    color: var(--bg-preto-3);
    /* margin-bottom: 10px; */
  }
}
</style>