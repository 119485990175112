<template>
  <section class="project-bg" :style="{ background: background }">
    <TheHeader :style="{ backgroundColor: backgroundpage }" />
    <div
      :class="{ 'banner-color': true }"
      :style="{ backgroundColor: backgroundpage }"
    >
      <div class="content container-sec">
        <div class="banner-content">
          <h2 class="generico-h2 titulo" v-if="project && project.bannerTitulo">
            {{ project.bannerTitulo }}
          </h2>
          <div class="imagem" v-if="project && project.bannerfoto">
            <img :src="project.bannerfoto" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="conteudo">
      <div class="container-sec mb-80">
        <div class="max-width">
          <div class="descricao">
            <h3 class="generico-h2" v-if="project && project.nome">
              {{ project.nome }}
            </h3>
            <p class="paragraph" v-if="project && project.descricaoResumo">
              {{ project.descricaoResumo }}
            </p>
          </div>

          <div class="data">
            <div>
              <span>Industria</span>
              <p v-if="project && project.industria">{{ project.industria }}</p>
            </div>
            <div>
              <span>Ano</span>
              <p v-if="project && project.ano">{{ project.ano }}</p>
            </div>
          </div>
          <span class="linha"></span>
          <div class="data servicos">
            <div>
              <span>Serviços</span>
              <p v-if="project && project.service.titulo">
                {{ project.service.titulo }}
              </p>
            </div>
          </div>
          <span class="linha"></span>
          <div class="desaresultado grid-2">
            <div>
              <h3>O Desafio</h3>
              <p class="paragraph" v-if="project && project.desafio">
                {{ project.desafio }}
              </p>
            </div>
            <div>
              <h3>O Resultado</h3>
              <p class="paragraph" v-if="project && project.resultado">
                {{ project.resultado }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="cor-preta-primaria cortipografia">
        <div class="container-sec">
          <div class="max-width">
            <span class="semi-titulo">IDENTIDADE VISUAL</span>
            <h3 v-if="project && project.corTitulo">
              {{ project.corTitulo }}
            </h3>

            <div class="cortipografia-content grid-2">
              <div class="tipogra">
                <img
                  v-if="project && project.fotocor"
                  :src="project.fotocor"
                  alt=""
                />
                <p class="paragraph" v-if="project && project.descricaoCor">
                  {{ project.descricaoCor }}
                </p>
              </div>
              <div class="cor">
                <img
                  v-if="project && project.fototipografia"
                  :src="project.fototipografia"
                  alt=""
                />
                <p
                  class="paragraph"
                  v-if="project && project.descricaoTipografia"
                >
                  {{ project.descricaoTipografia }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="galeria">
        <div class="galeria-content container-sec">
          <h3 class="preta">A Galeria</h3>
          <p
            class="paragraph max-width"
            v-if="project && project.descricaoGaleria"
          >
            {{ project.descricaoGaleria }}
          </p>
        </div>
      </div>

      <div class="midea container">
        <div
          id="carouselExample"
          class="carousel slide"
          v-if="project && project.photos && project.photos.length > 0"
        >
          <div class="carousel-inner">
            <div
              class="carousel-item active"
              v-for="photo in project.photos"
              :key="photo.id"
            >
              <img class="mb-4" :src="photo.photo" alt="" />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <!-- <router-link v-if="project.linkSocial" :to="project.linkSocial" target="_blank" class="mty-4">Visite a página</router-link> -->
      </div>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: "ProjectInfo",
  props: ["id"],

  components: { TheHeader },
  data() {
    return {
      background: "white",
      project: null,
      backgroundpage: "",
      photos: [],
    };
  },

  methods: {
    getProject() {
      api
        .get(`/projecto/${this.id}`)
        .then((response) => {
          // console.log(response.data);
          this.project = response.data;
          this.photos = response.data.photos;
          this.backgroundpage = response.data.hexadecimal;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {
    this.getProject();
  },
};
</script>

<style scoped>
.preta {
  color: var(--bg-preto) !important;
  
}
.banner-color {
  /* background: #ffa6a4; */
  padding: 5rem;
  /* height: 600px;  */
  box-sizing: border-box;
  margin-bottom: 80px;
}

.max-width {
  max-width: 1000px;
}

.banner-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 90px;
}

.conteudo {
  background: var(--bg-branco);
  padding-bottom: 80px;
}

.project-bg h3 {
  color: var(--bg-preto);
  margin-bottom: 20px;
}

.descricao {
  border-bottom: 1px solid var(--bg-hr);
  padding-bottom: 60px;
  margin-bottom: 20px;
}
.descricao p {
  color: var(--bg-preto);
}
.banner-content h2 {
  margin-bottom: 0px;
}

.data {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: max-content;
  gap: 80px;
  margin-bottom: 20px;
}

.data span {
  font-size: 20px;
  display: block;
  color: var(--bg-preto-b);
  margin-bottom: 15px;
}
.data p {
  font-size: 28px;
  display: block;
  color: var(--bg-preto);
  font-weight: 700;
  margin-bottom: 15px;
}

.desaresultado h3 {
  font-size: 28px;
  display: block;
  color: var(--bg-preto);
  font-weight: 700;
  margin-bottom: 15px;
}
.desaresultado p {
  font-size: 20px;
  color: var(--bg-preto);
}

.cortipografia {
  padding-top: 40px;
  padding-bottom: 2rem;
}
.cortipografia h3 {
  font-size: 60px;
  line-height: 1.1;
  color: var(--bg-branco);
}

.cortipografia-content img {
  margin-bottom: 20px;
}

.galeria h3 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 800;
  margin-bottom: 20px;
  color: var(--bg-preto);
}

.galeria-content {
  padding-top: 40px;
  padding-bottom: 40px;
}

.galeria p {
  color: var(--bg-preto);
}

.midea-content {
  background: #4d4d4d;
}

.midea a {
  font-size: 30px;
  font-weight: 800;
  color: var(--bg-preto);
  padding-bottom: 90px;
  text-decoration: underline;
}

.midea a:hover {
  color: var(--bg-azul);
  text-decoration: none;
}

.data.servicos {
  display: grid;
  grid-template-columns: 1fr;
}

@media (max-width: 800px) {
  .galeria h3 {
    font-size: 35px;
    line-height: 38px;
    font-weight: 800;
    margin-bottom: 20px;
    color: var(--bg-branco);
  }

  .descricao {
    padding-bottom: 15px;
  }

.linha{
  margin-bottom: 10px;
}
  .cortipografia h3 {
    font-size: 25px;
    padding-bottom: 20px;
  }
  .banner-color {
    margin-bottom: 40px;
  }
  .banner-color {
    padding: 5rem 1rem;
    box-sizing: border-box;
    margin-bottom: 40px;
  }
  .banner-content {
    gap: 30px;
  }
}
@media (max-width: 500px) {
  .galeria-content {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  .banner-content {
    grid-template-columns: 1fr;
  }
  .generico-h2.titulo {
    font-size: 45px;
    line-height: 45px !important;
    text-align: center;
  }
  .project-bg h3 {
    margin-top: 16px;
    margin-bottom: 8px;
    /* color: var(--bg-preto); */
  }

  .data p {
    font-size: 20px;
  }

  .desaresultado h3 {
    font-size: 25px;
    margin-bottom: 8px;
  }
  .desaresultado p {
    font-size: 18px;
    color: var(--bg-preto);
  }
}
</style> 