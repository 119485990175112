import { api } from "@/services.js";

export default {
  data() {
    return {
      homeTitulo: "",
      homeDescricao: "",
      portfolioDescricao: "",
      portfolioTitulo: "", 
      projectoTitulo: "",
      servicoDescricao: "", 
      sobreDescricao: "",
      servicoTitulo: "",
      sobreTitulo: "",
      sobrefoto: "",
    };
  },
  methods: {
    fetchData() {
      api.get("/generics").then((response) => {
        // console.log(response.data);
        this.generics = response.data;
        this.homeTitulo = response.data.homeTitulo;
        this.homeDescricao = response.data.homeDescricao;
        this.portfolioTitulo = response.data.portfolioTitulo;
        this.portfolioDescricao = response.data.portfolioDescricao;
        this.projectoTitulo = response.data.projectoTitulo;
        this.servicoDescricao = response.data.servicoDescricao;
        this.sobreDescricao = response.data.sobreDescricao;
        this.servicoTitulo = response.data.servicoTitulo;
        this.sobreTitulo = response.data.sobreTitulo;
        this.servicofoto = response.data.servicofoto;
        this.sobrefoto = response.data.sobrefoto;
      })
      .catch(error => {
        console.error('Erro ao carregar dados:', error);
      });
    },
  }
}