<template>
  <div id="app">
    <!-- <TheHeader /> -->
    <main id="main">
      <router-view />
    </main>
    <TheFooter />
  </div>
</template>

<script>
// import TheHeader from "@/components/TheHeader.vue";
// import TheBanner from '@/components/TheBanner.vue';
import TheFooter from "@/components/TheFooter.vue";

export default {
  components: {
    // TheHeader,
    TheFooter,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

body {
  margin: 0px;
  font-family: "Inter", Helvetica, sans-serif;
  background: #000;

  /* color: #111111; */
  /* margin-bottom: 500px; */
}

:root {
  --bg-preto: #010101;
  --bg-preto-sec: #141414;
  --bg-preto-2: #3e3e3e;
  --bg-preto-3: #d9d9d9;
  --bg-preto-b: #8b8b8b;
  --bg-preto-p: #b2b2b2;
  --bg-branco: #fff;
  --bg-cinza: #f2f0f0;
  --bg-azul: #5c66ff;
  --bg-header: #fcfcfc;
  --bg-hr: #202020;
}

.cor-preta-primaria {
  background: var(--bg-preto);
}

.cor-preta-sec {
  background: var(--bg-preto-sec);
}
.bg-verde {
  background: #00a076;
}

.bg-cinza {
  background: var(--bg-cinza);
}

.cor-azul {
  background: var(--bg-azul);
}

h1,
h2,
h3,
h4,
ul,
p,
blockquote,
dd,
iframe {
  margin: 0px;
  padding: 0px;
}

ul {
  padding: 0px;
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.container {
  box-sizing: border-box;
  max-width: 1400px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

.ty-bg {
  padding-top: 5rem;
}

.container-sec {
  box-sizing: border-box;
  max-width: 1300px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

/* BOTAO */
.botao {
  display: inline-block;
  padding: 12px 32px;
  background: var(--bg-azul);
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  color: var(--bg-branco);
  font: 600 1.125rem/1.35 "Inter", sans-serif;
  max-width: max-content;
  border: none;
  cursor: pointer;
}

.botao::after {
  content: "";
  display: inline-block;
  width: 18px;
  height: 10px;
  margin-left: 12px;
  background: url(./assets/svg/seta.svg);
  transition: transform 0.2s;
}

.botao:hover {
  background: linear-gradient(#5c66ff, #3845fa);
}

.botao.secundario:hover {
  background: var(--cor-9);
}

.botao.seta {
  display: flex;
  align-items: center;
}

.botao.seta:hover::after {
  transform: translateX(4px);
}

.semi-titulo {
  font-size: 20px;
  line-height: 60px;
  font-weight: 400;
  margin-bottom: 80px;
  color: var(--bg-preto-p);
  text-transform: uppercase;
}

.semi-titulo::before {
  content: "";
  width: 60px;
  display: inline-block;
  border-bottom: 8px solid var(--bg-azul);
  justify-content: center;
  margin-right: 20px;
}

.generico-h2 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 800;
  margin-bottom: 80px;
  color: var(--bg-branco);
}

.generico-page-h2 {
  font-size: 90px;
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 40px;
  color: var(--bg-branco);
}

.paragraph {
  font-size: 22px;
  font-weight: 400;
  color: var(--bg-preto-3);
  display: inline-block;
  line-height: 1.5;
  max-width: 1000px;
}

.mb-4 {
  margin-bottom: 20px;
}
.mb-80 {
  margin-bottom: 80px;
}

a.router-link-exact-active,
a:hover {
  color: var(--bg-azul);
}

a.router-link-exact-active.pro {
  color: var(--bg-branco);
  background: linear-gradient(#5c66ff, #3845fa);
}

.pro:hover {
  color: #fff !important;
}

.borda {
  border: 4px solid var(--bg-azul);
  margin-top: 8rem;
}

.portfolio-bg.borda {
  box-sizing: border-box;
  max-width: 1400px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.contacto {
  margin-top: 80px;
  background: var(--bg-preto);
}

.contacto h4 {
  font-size: 50px;
  margin-bottom: 40px;
  font-weight: 800;
  color: var(--bg-branco);
}

.about-bg {
  padding-top: 5rem;
}

.linha {
  border-bottom: 1px solid var(--bg-preto);
  display: block;
  margin-bottom: 30px;
}

.grid-2 {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 60px;
}

.mty-4 {
  margin-top: 10rem;
}

@media (max-width: 800px) {
  .generico-h2 {
    font-size: 30px;
    line-height: 35px;
    font-weight: 800;
    margin-bottom: 40px;
    color: var(--bg-branco);
  }

  .mb-80 {
    margin-bottom: 40px;
  }
  .generico-page-h2 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 800;
    margin-bottom: 40px;
    color: var(--bg-branco);
  }

  .paragraph {
    font-size: 18px;
    font-weight: 300;
    color: var(--bg-preto-3);
    display: inline-block;
    line-height: 1.5;
  }
}

.midea img {
  border-radius: 20px;
}

.midea-content {
  width: 100%;
  height: 650px;
  border-radius: 30px;
  /* background: #fff; */
}

@media (max-width: 500px) {
  .midea {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: auto;
    margin-right: auto;
  }
  .ty-bg {
    padding-top: 3rem;
  }

  .midea-content {
    height: 250px;
    border-radius: 0px;
  }

  .grid-2 {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .semi-titulo {
    font-size: 15px;
  }
  .semi-titulo::before {
    width: 50px;
  }
  .contacto h4 {
    font-size: 35px;
    margin-bottom: 40px;
  }

  .contacto {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .borda {
    margin-top: 5rem;
  }
}
</style>
