<template>
  <section>
    <TheHeader />
    <AboutPage />
  </section>
</template>

<script>
import AboutPage from "@/components/AboutPage.vue";
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: "AboutView",

  components: {
    AboutPage,
    TheHeader,
  },
};
</script>

<style>
</style>