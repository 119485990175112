<template>
  <header class="header-bg">
    <div class="header-content container">
      <router-link to="/"
        ><img src="@/assets/logos/logotipo.png" width="200" height="74" alt=""
      /></router-link>
      <div class="header-menu">
        <nav class="menu-nav">
          <ul v-show="!mobileMenuOpen">
            <router-link to="/portfolio">Portfólio</router-link>
            <router-link to="/services">Serviços</router-link>
            <router-link to="/sobre">Sobre nós</router-link>
            <router-link class="botao seta pro" to="/projecto"
              >Seu projecto</router-link
            >
          </ul>
          <div class="menu" @click="toggleMobileMenu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 24 24"
              style="fill: #fff; transform: ; msfilter: "
            >
              <path d="M4 6h16v2H4zm4 5h12v2H8zm5 5h7v2h-7z"></path>
            </svg>
          </div>
          <!-- Adicione um novo elemento para o menu de navegação para celular -->
          <ul class="mobile-menu" v-show="mobileMenuOpen" @click="toggleMobileMenu">
            <router-link to="/portfolio">Portfólio</router-link>
            <router-link to="/services">Serviços</router-link>
            <router-link to="/sobre">Sobre nós</router-link>
            <router-link class="botao seta pro" to="/projecto"
              >Seu projecto</router-link
            >
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "TheHeader",

  data() {
    return {
      mobileMenuOpen: false,
    };
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
  },
};
</script>

<style scoped>
.menu {
  display: none;
}
.header-bg {
  background-color: var(--bg-preto);
  /* border-bottom: 1px solid var(--bg-hr); */
}

.header-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
  padding: 10px;
}

.header-menu ul {
  display: flex;
  align-items: center;
  color: white;
  gap: 40px;
  font-size: 18px;
}

/* MENU CELULAR  */
.mobile-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px; /* Ajuste conforme necessário */
  left: 0;
  width: 100%;
  height: 100vh; /* Defina a altura para 100% da altura da tela */
  background-color: var(--bg-preto);
}

.mobile-menu a {
  padding: 10px;
  color: white;
  text-decoration: none;
  border-bottom: 1px solid var(--bg-hr);
}

/* Adicione estilos para ocultar o menu em dispositivos maiores */
.hide-mobile-menu {
  display: none;
}
/* FIM MENU CELULAR */

@media (max-width: 900px) {
  .header-bg {
    border-bottom: none;
  }
  .header-menu ul {
    display: none;
  }
  .menu {
    display: block;
    width: 40px;
  }
  .header-menu ul {
    display: flex;
  }
  .header-menu ul:not(.mobile-menu) {
    display: none;
  }
  .menu {
    display: block;
    width: 40px;
  }

  /* Adicione estilos para o menu de navegação para celular */
  .mobile-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 59px; /* Ajuste conforme necessário */
    left: 0;
    width: 100%;
    background-color: var(--bg-preto);
  }
  .mobile-menu a {
    padding: 10px;
    font-size: 20px;
    color: white;
    text-decoration: none;
    border-bottom: none;
  }

  .header-menu ul {
    display: flex;
    align-items: center;
    color: white;
    gap: 20px;
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .header-content img {
    width: 150px;
  }
}
</style>