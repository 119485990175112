import axios from "axios";

// const axiosInstance = axios.create({
//   baseURL: "http://localhost:8080/api"

// });
const axiosInstance = axios.create({
  baseURL: "https://backoffice.mirimo.ao/api/"

});

export const api = {
  get(endpoint){
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body){
    return axiosInstance.get(endpoint, body);
  }
};